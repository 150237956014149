import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import Hero from '../components/Hero/Hero';
import Section from '../components/Section/Section';
import PageData from '../data/pages/verifier-terms-conditions/index.json';
import '../../sass/_table.scss';

const Privacy = ({ location }) => (
  <Layout location={location}>
    <main className="navigation-spacer">
      <Helmet>
        <title>{PageData.metaTitle} </title>
        <meta name="description" content={PageData.metaDesc} />
        <link
          href="https://www.latitudefinancial.com.au/privacy/"
          rel="canonical"
        />
      </Helmet>
      <Hero
        h1="Verifier's terms of service"
        bg={require('../images/hero/utility-hero.jpg').default}
        darkBg
      />

      <Section id="statement" className="bg-lightest">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            {ReactHTMLParser(PageData.pagecontent)}
          </div>
        </div>
      </Section>
    </main>
  </Layout>
);

export default Privacy;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/data/pages/privacy/*.md" } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
          }
        }
      }
    }
  }
`;
